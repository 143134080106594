<script>
import GlobalVue from '@helper/Global.vue';
import BaseVue from '@frontend/Base.vue';
import Gen from '@helper/Gen';


export default {
	extends: GlobalVue,
	data() {
		return {
			data:{},
		}
	},
	async mounted() {
		await this.ready()
		this.getSlider()
		this.removeHeadBg()
	},
	methods: {
		getSlider(){
			Gen.apirest("/home-slider", {},(err,resp)=>{
				if(err) console.log("home slider error "+err)
				this.data = resp.data
				setTimeout(()=>{
					global.SEMICOLON.initialize.fullScreen()
					Gen.loadScript("/frontend/js/custom.js", false)
					$("#FoMain #wrapper > *:not(#header)").css("opacity", 1)
					global.SEMICOLON.slider.init();
				}, 100)
			})
		},
		removeHeadBg(){
			$("#header").removeClass("no-slider");
		}
	},
}
</script>
<template>
	<section v-if="data.length" id="slider" class="slider-element swiper_wrapper full-screen force-full-screen clearfix">
		<div class="swiper-container swiper-parent">
			<div class="swiper-wrapper">
			<a v-for="(v,k) in data" :href="v.as_link" :target="v.as_link_target" class="swiper-slide" :style="'background-image:url('+uploader(v.as_img)+');'" :data-img-mobile="uploader(v.as_img_mobile)"></a>
			</div>
			<div class="slider-arrow-left"><i class="icon-angle-left"></i></div>
			<div class="slider-arrow-right"><i class="icon-angle-right"></i></div>
			<div class="swiper-pagination"></div>
		</div>
		<a href="#" data-scrollto="#content" data-offset="100" class="dark one-page-arrow"><i
				class="icon-angle-down infinite animated fadeInDown"></i></a>
	</section>
</template>